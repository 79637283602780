import Vue, { defineAsyncComponent } from "vue"

let VendorProfilePage = {
  initialize() {
    let el = document.getElementById("vendor-follow-btn-container")

    if (el != null) {
      const VendorFollowButton = defineAsyncComponent(() => import("@/pages/VendorProfilePage/VendorFollowButton.vue"))
      this.app = new Vue({
        el: el,
        render: (h) =>
          h(VendorFollowButton, {
            props: {
              apiUrl: el.dataset.apiUrl,
              defaultFollowing: el.dataset.following === "true",
              vendorId: parseInt(el.dataset.vendorId),
            },
          }),
      })
    }
  },
}

document.addEventListener("turbo:load", () => {
  VendorProfilePage.initialize()
})
