import Vue, { defineAsyncComponent } from "vue"
import { artworkPage } from "./page.js"

const renderListingImage = () => {
  const loadListingMainImage = () => {
    const ListingMainImage = defineAsyncComponent(() => import("@/pages/Listing/Gallery/MainImage.vue"))
    const el = document.getElementById("listing-main-attachment-vue")

    new Vue({
      el,
      render: (h) => h(ListingMainImage, {}),
    })
  }

  artworkPage() && loadListingMainImage()
}

document.addEventListener("turbo:load", renderListingImage)
