import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"

document.addEventListener("turbo:load", function () {
  const target = document.getElementById("nav-guest-favourite")

  if (target) {
    const GuestFavourite = defineAsyncComponent(() => import("@/components/Nav/Guest/Favourite.vue"))
    window.GuestFavourite = new Vue({
      el: target,
      store,
      apolloProvider,
      render: (h) =>
        h(GuestFavourite, {
          props: {
            onboardPath: target.dataset.onboardPath,
            afterOnboardPath: target.dataset.afterOnboardPath,
            afterOnboardMessage: target.dataset.afterOnboardMessage,
          },
        }),
    })
  } else {
    // User is logged in, reset guest
    store.dispatch("guest/reset")
  }
})

window.setGuestFavouriteIds = () => {
  const favouriteIds = store.state.guest.favourites.map((l) => l.id).join(",")

  const target = document.getElementById("add_favourites")
  if (target) target.value = favouriteIds

  const oauthLoginIds = ["fb-login", "google-login"]
  oauthLoginIds.forEach((oauthLoginId) => {
    const target = document.getElementById(oauthLoginId)
    if (target) {
      const url = new URL(target.href)
      url.searchParams.append("add_favourites", favouriteIds)
      target.href = url
    }
  })
}

store.subscribe((mutation, state) => {
  if (mutation?.type == "guest/SET_FAVOURITES") {
    window.setGuestFavouriteIds()
  }
})
document.addEventListener("turbo:load", window.setGuestFavouriteIds)
