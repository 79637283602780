import axios from "axios"
import http from "@/lib/Http"
import MyArtworkTabs from "@/lib/MyArtworkTabs"

function baseUrl() {
  return window.location.protocol + "//" + window.location.host + "/" + window.location.pathname.split("/")[1]
}

const defaultFilters = () => {
  return {
    page: 1,
    per: 20,
    sort: "display_order",
    availability: "available",
  }
}

const FilterModule = {
  namespaced: true,
  state: {
    tab: MyArtworkTabs.AVAILABLE,
    submissions: {},
    competitions: [],
    listings: [],
    listings_count: 0,
    available_count: null,
    gallery_count: null,
    sold_count: null,
    unavailable_count: null,
    draft_count: null,
    scheduled_count: null,
    exclusive_count: null,
    print_on_demand_count: null,
    commissioned_count: null,
    infiniteState: null,
    loading: true,
    holidayMode: null,
    filters: {
      ...defaultFilters(),
    },
  },
  mutations: {
    resetSubmissions(state, { competitionSlug, listingId }) {
      state.submissions[competitionSlug].splice(state.submissions[competitionSlug].indexOf(listingId), 1)
    },
    nominate(state, { competitionSlug, listingId }) {
      if (state.submissions[competitionSlug]) {
        state.submissions[competitionSlug].push(listingId)
      } else {
        state.submissions[competitionSlug] = [listingId]
      }
    },
    setListingsCount(state, count) {
      state.listings_count = count
    },
    setAvailableCount(state, count) {
      state.available_count = count
    },
    setGalleryCount(state, count) {
      state.gallery_count = count
    },
    setSoldCount(state, count) {
      state.sold_count = count
    },
    setUnavailableCount(state, count) {
      state.unavailable_count = count
    },
    setDraftCount(state, count) {
      state.draft_count = count
    },
    setScheduledCount(state, count) {
      state.scheduled_count = count
    },
    setExclusiveCount(state, count) {
      state.exclusive_count = count
    },
    setPrintOnDemandCount(state, count) {
      state.print_on_demand_count = count
    },
    setCommissionedCount(state, count) {
      state.commissioned_count = count
    },
    clearListings(state) {
      state.listings = []
    },
    addListings(state, listings) {
      state.listings.push(...listings)
    },
    setListings(state, listings) {
      state.listings = listings
    },
    setCompetitions(state, competitions) {
      state.competitions = competitions
    },
    setSubmissions(state, { competitions, submissions }) {
      state.submissions = []
      submissions.forEach((submission) => {
        let competition = competitions.find(({ id }) => id == submission.competition_id)
        if (competition) {
          if (state.submissions[competition.slug]) {
            state.submissions[competition.slug].push(submission.listing_id)
          } else {
            state.submissions[competition.slug] = [submission.listing_id]
          }
        }
      })
    },
    setListing(state, { id, listing }) {
      let replace = state.listings.find((listing) => listing.id == id)
      state.listings.splice(state.listings.indexOf(replace), 1, listing)
    },
    addFilter(state, { filterType, filter }) {
      state.filters[filterType].push(filter)
    },
    replaceFilter(state, filter) {
      state.filters = filter
    },
    setFilter(state, { filterType, filter }) {
      state.filters[filterType] = filter
    },
    removeFilter(state, { filterType, filter }) {
      state.filters[filterType].splice(state.filters[filterType].indexOf(filter), 1)
    },
    resetFilters(state) {
      state.filters = defaultFilters()
    },
    clearFilter(state, filterType) {
      state.filters[filterType] = defaultFilters()[filterType]
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setTab(state, tab) {
      state.tab = tab
    },
    nextPage(state, infiniteState) {
      state.filters.page += 1
      state.infiniteState = infiniteState
    },
    resetPage(state) {
      state.filters.page = 1
      if (state.infiniteState) state.infiniteState.reset()
    },
    setHolidayMode(state, value) {
      state.holidayMode = value
    },
  },
  actions: {
    resetSubmissions({ commit }, { competitionSlug, listingId }) {
      commit("resetSubmissions", { competitionSlug, listingId })
    },
    initListings({ commit, getters, state, dispatch }, filters) {
      commit("resetFilters")
      commit("replaceFilter", { ...state.filters, ...filters })
      dispatch("fetchListings", false)
    },
    fetchListings({ commit, state }, add = true) {
      commit("setLoading", true)
      if (!add) {
        commit("clearListings")
        commit("resetPage")
      }
      const params = { ...state.filters }
      if (state.tab == MyArtworkTabs.CHANGE_ORDER) {
        params["sort"] = "display_order"
      }
      http.get("/api/my/listings", { params }).then((response) => {
        commit("setListingsCount", response.data.listings_count)
        commit("setLoading", false)
        if (add) {
          commit("addListings", response.data.listings)
        } else {
          commit("setListings", response.data.listings)
          commit("setCompetitions", response.data.competitions)
          commit("setSubmissions", response.data)
        }
        if (response.data.listings.length && response.data.listings.length == state.filters.per) {
          if (state.infiniteState) state.infiniteState.loaded()
        } else {
          if (state.infiniteState) state.infiniteState.complete()
        }
      })
    },
    fetchListingStats({ commit, state }, { apiUrl, listingId }) {
      let replace = state.listings.find((listing) => listing.id == listingId)
      http.get(apiUrl + "/listings/" + listingId + "/stats?days=1800").then((response) => {
        replace.stats.views = response.data.views
      })
    },
    nominate({ commit }, { competitionSlug, listingId }) {
      commit("setLoading", true)
      axios
        .get(`/my/competitions/${competitionSlug}/submissions/new`, { params: { listing_id: listingId } })
        .then((response) => {
          document.querySelectorAll(
            `[role="competition-submission-modal-body-${competitionSlug}-${listingId}"]`,
          )[0].innerHTML = response.data
          commit("setLoading", false)
        })
    },
    withdraw({ commit }, { competitionSlug, listingId }) {
      commit("nominate", { competitionSlug, listingId: null })
      commit("setLoading", true)
      http.delete(`/my/competitions/${competitionSlug}/submissions/${listingId}`).then(() => {
        commit("setLoading", false)
      })
    },
    sortListings({ commit, state }, { oldIndex, newIndex, listingId }) {
      if (oldIndex !== newIndex && state.filters.sort == "display_order" && !state.loading) {
        commit("setLoading", true)
        http
          .put("/api/my/listings/" + listingId + "/sort", {
            tab: state.tab,
            old_index: oldIndex,
            new_index: newIndex,
          })
          .then((response) => {
            commit("setLoading", false)
          })
      }
    },
    addToGallery({ commit, state, dispatch }, listingId) {
      if (!state.loading) {
        commit("setLoading", true)
        http.put("/api/my/listings/" + listingId + "/add_to_gallery").then((response) => {
          state.gallery_count += 1
          dispatch("fetchListings", false)
        })
      }
    },
    removeFromGallery({ commit, state, dispatch }, listingId) {
      if (!state.loading) {
        commit("setLoading", true)
        http.delete("/api/my/listings/" + listingId + "/remove_from_gallery").then((response) => {
          state.gallery_count -= 1
          dispatch("fetchListings", false)
        })
      }
    },
    moveDown({ commit, state, dispatch }, listing) {
      if (state.filters.sort == "display_order" && !state.loading) {
        var oldIndex = state.listings.indexOf(listing)
        var newIndex = oldIndex + 1
        if (newIndex < state.listings_count) {
          state.listings[newIndex] = state.listings.splice(oldIndex, 1, state.listings[newIndex])[0]
          dispatch("sortListings", { oldIndex: oldIndex, newIndex: newIndex, listingId: listing.id })
        }
      }
    },
    moveUp({ commit, state, dispatch }, listing) {
      if (state.filters.sort == "display_order" && !state.loading) {
        var oldIndex = state.listings.indexOf(listing)
        var newIndex = oldIndex - 1
        if (newIndex >= 0) {
          state.listings[newIndex] = state.listings.splice(oldIndex, 1, state.listings[newIndex])[0]
          dispatch("sortListings", { oldIndex: oldIndex, newIndex: newIndex, listingId: listing.id })
        }
      }
    },
    selectFilter({ commit, state, dispatch }, { filterType, filter }) {
      commit(state.filters[filterType].includes(filter) ? "removeFilter" : "addFilter", { filterType, filter })
    },
    setFilter({ commit, state, dispatch }, { filterType, filter }) {
      commit("setFilter", { filterType, filter })
      dispatch("fetchListings", false)
    },
    resetFilters({ commit, dispatch }) {
      commit("resetFilters")
      dispatch("fetchListings", false)
    },
    clearFilter({ commit, dispatch }, filterType) {
      commit("resetPage")
      commit("clearFilter", filterType)
      dispatch("fetchListings", false)
    },
    setListing({ commit }, { id, listing }) {
      commit("setListing", { id, listing })
    },
    setTab({ commit, dispatch }, { tab, pushHistory = true }) {
      commit("setTab", tab)
      var availability = null
      switch (tab) {
        case MyArtworkTabs.AVAILABLE:
          availability = "available"
          break
        case MyArtworkTabs.CHANGE_ORDER:
          availability = "all"
          break
        case MyArtworkTabs.GALLERY:
          availability = "gallery"
          break
        case MyArtworkTabs.SOLD:
          availability = "sold"
          break
        case MyArtworkTabs.UNAVAILABLE:
          availability = "unavailable"
          break
        case MyArtworkTabs.DRAFT:
          availability = "draft"
          break
        case MyArtworkTabs.SCHEDULED:
          availability = "scheduled"
          break
        case MyArtworkTabs.EXCLUSIVE:
          availability = "exclusive"
          break
        case MyArtworkTabs.PRINT_ON_DEMAND:
          availability = "print_on_demand"
          break
        case MyArtworkTabs.COMMISSIONED:
          availability = "commissioned"
          break
      }
      if (availability == "available" && pushHistory) {
        window.history.pushState({ turbo: {} }, "", `${baseUrl()}/artworks`)
      } else if (availability != "available" && pushHistory) {
        window.history.pushState({ turbo: {} }, "", `${baseUrl()}/artworks/${availability}`)
      }
      dispatch("setFilter", { filterType: "availability", filter: availability })
    },
    nextPage({ dispatch, commit }, infiniteState) {
      commit("nextPage", infiniteState)
      dispatch("fetchListings")
    },
    setListings({ commit }, listings) {
      commit("setListings", listings)
    },
    setAvailableCount({ commit }, count) {
      commit("setAvailableCount", count)
    },
    setGalleryCount({ commit }, count) {
      commit("setGalleryCount", count)
    },
    setSoldCount({ commit }, count) {
      commit("setSoldCount", count)
    },
    setUnavailableCount({ commit }, count) {
      commit("setUnavailableCount", count)
    },
    setDraftCount({ commit }, count) {
      commit("setDraftCount", count)
    },
    setScheduledCount({ commit }, count) {
      commit("setScheduledCount", count)
    },
    setExclusiveCount({ commit }, count) {
      commit("setExclusiveCount", count)
    },
    setPrintOnDemandCount({ commit }, count) {
      commit("setPrintOnDemandCount", count)
    },
    setCommissionedCount({ commit }, count) {
      commit("setCommissionedCount", count)
    },
    setHolidayMode({ commit }, { value, returnDate, sendRequest }) {
      commit("setHolidayMode", value)
      if (sendRequest) {
        http.put("/api/my/listings/holiday_mode", {
          holiday_mode: value,
          return_date: returnDate,
        })
      }
    },
    makeAvailable({ state, commit, dispatch }, listingId) {
      http.put("/api/my/listings/" + listingId + "/make_available").then((response) => {
        dispatch("fetchListings", false)
        commit("setUnavailableCount", state.unavailable_count - 1)
      })
    },
  },
}

export default FilterModule
