import Http from "@/lib/Http"

const perListings = 12

const ArtistModule = {
  namespaced: true,
  state: {
    loading: true,
    sortOptions: {},
    artist: {},
    filter: {
      sort: "best-match",
      scope: "available",
      page: 1,
      per: 20,
    },
    following: false,
    tab: "AVAILABLE",
    listings: [],
    meta: {
      totalPages: 0,
    },
    studioPost: {},
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setMeta(state, meta) {
      state.meta = meta
    },
    setListings(state, listings) {
      state.listings = listings
    },
    setSortOptions(state, sortOptions) {
      state.sortOptions = sortOptions
    },
    setArtist(state, artist) {
      state.tab = "AVAILABLE"
      state.artist = artist
      state.following = artist.following
      state.filter.page = 1
    },
    setFollowing(state, follow) {
      state.following = follow
      state.follow = follow
    },
    setStudioPost(state, studioPost) {
      state.studioPost = studioPost
    },
    setTab(state, tab) {
      state.tab = tab
    },
    mergeFilter(state, filter) {
      state.filter = Object.assign(state.filter, filter)
    },
  },
  actions: {
    setSortOptions({ commit }, sortOptions) {
      commit("setSortOptions", sortOptions)
    },
    setArtist({ commit }, artist) {
      commit("setArtist", artist)
    },
    setStudioPost({ commit }, studioPost) {
      commit("setStudioPost", studioPost)
    },
    fetchListings({ commit, state }) {
      commit("setLoading", true)

      const { id } = state.artist
      commit("setListings", [])

      Http.get(`/api/artists/${id}/listings`, {
        params: state.filter,
      }).then(({ data }) => {
        const meta = state.meta
        const totalPages = data.total_pages
        const { listings } = data

        commit("setListings", listings)
        commit("setMeta", { ...meta, ...{ totalPages } })
        commit("setLoading", false)
      })
    },
    unfollow({ state, commit }) {
      commit("setFollowing", false)
      const { id } = state.artist
      const resource = "Artist"

      Http.delete(`/api/follows/${id}`, {
        params: { resource },
      })
    },
    follow({ state, commit }) {
      commit("setFollowing", true)
      const { id } = state.artist
      const resource = "Artist"

      Http.post("/api/follows", {
        resource,
        id,
      })
    },
    setTab({ commit }, tab) {
      commit("setTab", tab)
    },
    mergeFilter({ commit, dispatch }, filter) {
      commit("mergeFilter", filter)
      dispatch("fetchListings")
    },
  },
}

export default ArtistModule
