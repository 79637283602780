import Uppy from "@uppy/core"
import FileInput from "@uppy/file-input"
import XHRUpload from "@uppy/xhr-upload"
import ProgressBar from "@uppy/progress-bar"
import imagesLoaded from "imagesloaded"
import Vue, { defineAsyncComponent } from "vue"

const setInboxMessageCount = (count) => {
  const inboxMessageCounters = document.querySelectorAll(".unread-inbox-count")

  for (let counter of inboxMessageCounters) {
    counter.innerHTML = count
    counter.style.display = count == 0 ? "none" : null
  }
}

const setArchiveMessageCount = (count) => {
  const archiveCounters = document.querySelectorAll(".archive-inbox-count")

  for (let counter of archiveCounters) {
    counter.innerHTML = count
    counter.style.display = count == 0 ? "none" : null
  }
}

$(document).on("turbo:load", function () {
  const match = document.location.pathname.match(/\/my\/inbox\/archives\/(\d+)(\/?)$/)

  $(document).on("click", ".message-unarchive", function (e) {
    const unread = $(this).data("unread")

    if (unread) {
      var inboxCount = parseInt($(".unread-inbox-count").first().html())
      var archiveCount = parseInt($(".archive-inbox-count").first().html())

      setInboxMessageCount(inboxCount + 1)
      setArchiveMessageCount(archiveCount - 1)
    }
  })
})

$(document).on("turbo:load", function () {
  const inboxMessageCounters = $(".unread-inbox-count")
  const sampleCount = parseInt(inboxMessageCounters.first().html())
  if (sampleCount > 0) inboxMessageCounters.show()

  const match = document.location.pathname.match(/\/my\/inbox\/(\d+)(\/?)$/)

  if (match) {
    const $form = $("#new_inbox_message")
    const $fileInput = $("#message-file")
    const uppy = new Uppy().use(FileInput).use(XHRUpload, { endpoint: "/api/upload" }).use(ProgressBar, {
      target: ".conversation__upload-progressbar",
    })
    let fileId = null

    $(document).on("change", "#message-file", function (e) {
      const $submit = $form.find('input[type="submit"]')

      const file = e.target.files[0]

      if (file) {
        if (file.size >= 5242880) {
          const fileSizeHtml = document.getElementById("file-size")
          fileSizeHtml.insertAdjacentHTML(
            "afterend",
            '<span class="help-block text-red-500" id="error-message">Image is too large (max is 5 Mb)</span>',
          )
        } else {
          const { name, type } = file
          $submit.prop("disabled", true)
          $("#listing-action").hide()
          $("#inbox_message_listing_id").val("")

          try {
            uppy.addFile({
              source: "file input",
              data: file,
              name,
              type,
            })
            uppy.upload()
          } catch (err) {
            $submit.prop("disabled", false)
          }
        }
      } else {
        $("#image-action").hide()
        $(".uppy-ProgressBar-inner").width(0)
      }

      uppy.on("upload-success", (file, response) => {
        var uploadedFileData = JSON.stringify(response.body)
        fileId = file.id
        $("#inbox_message_image").val(uploadedFileData)
        $("#image-action").show()
        $("#image-filename").text(file.name)
        $submit.prop("disabled", false)
      })
    })

    const small = window.matchMedia("(max-width: 992px)")

    if (small.matches) {
      document.getElementById("conversation-policy").classList.remove("in")
    }

    const $conversation = $("#conversation")
    const hideImageErrorMessage = () => {
      const errorMessage = document.getElementById("error-message")
      if (errorMessage) {
        errorMessage.style.display = "none"
      }
    }

    imagesLoaded("#conversation", function () {
      $conversation.scrollTop($("#conversation")[0].scrollHeight)
    })

    $("#button-upload-alias").on("click", function () {
      hideImageErrorMessage()
    })

    $(document)
      .on("click", "#image-upload-alias", function () {
        $fileInput.click()
        hideImageErrorMessage()
      })

    $(document).on("click", "#image-remove", function (e) {
      e.preventDefault()
      $fileInput.val("").trigger("change")
      uppy.removeFile(fileId)
      $("#inbox_message_image").val("").trigger("change")
    })

    $("#listing-search-form").on("submit", function (e) {
      e.preventDefault()

      const errorEl = $("#listing-result-error")
      errorEl.hide()
      $("#listing-result").hide()

      const showError = (text) => {
        errorEl.show().html(text)
      }
      const url = $("#listing-url-input").val()

      const match = url.match(/([^\/]+)\/Artwork\/([^\/]+)/)

      if (match) {
        const [_, artist_id, listing_id] = match

        $.getScript({
          url: "/my/inbox/listing",
          data: {
            artist_id,
            listing_id,
          },
        })
      } else {
        showError("Please enter a valid Artwork url")
      }
    })

    $("#listing-search-modal").on("click", "#listing-send", function (e) {
      const listingId = $(this).data("listing-id")
      const listingTitle = $(this).data("listing-title")

      $("#listing-search-modal").modal("hide")
      $("#image-action").hide()
      $("#listing-action").show()
      $("#listing-title").html(listingTitle)
      $("#inbox_message_listing_id").val(listingId)
      $("#message-file").val("").trigger("change")
      $("#inbox_message_image").val("").trigger("change")
      hideImageErrorMessage()
    })

    $(document).on("click", "#listing-remove", function (e) {
      e.preventDefault()
      $("#listing-action").hide()
      $("#inbox_message_listing_id").val("")
    })

    const calculatorEl = document.getElementById("listing-calculator")

    if (calculatorEl != null) {
      const ListingShippingCalculator = defineAsyncComponent(() =>
        import("@/components/Conversation/ListingShippingCalculator.vue"),
      )

      new Vue({
        el: calculatorEl,
        render: (h) =>
          h(ListingShippingCalculator, {
            props: {},
          }),
      })
    }

    // end if match
  }
})
