import componentInitializer from "@/frontend/utils/componentsIntitializer"
import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"
import imagesLoaded from "imagesloaded"
import { i18n } from "@/lib/vue/plugins/i18n"

let listingFirstLoad = true

const initializeListingsPage = () => {
  let element = document.getElementById("vue-listings-page")

  if (element != null) {
    const ListingsPage = defineAsyncComponent(() => import("@/pages/ListingsPage/index.vue"))
    const filters = JSON.parse(element.dataset.filters)
    window.listingPage = new Vue({
      el: element,
      store,
      apolloProvider,
      i18n,
      render: (h) =>
        h(ListingsPage, {
          props: {
            filterData: filters,
            artAdvisoryUrl: element.dataset.artAdvisoryUrl,
            longtail: {
              id: element.dataset.longtailId,
              keyword: element.dataset.longtailKeyword,
              headline: element.dataset.longtailHeadline,
              content: element.dataset.longtailContent,
            },
            competition: {
              slug: element.dataset.competitionSlug,
              name: element.dataset.competitionName,
              categories: JSON.parse(element.dataset.competitionCategories || "[]"),
              categoryTitle: element.dataset.competitionCategoryTitle,
            },
            isArtist: JSON.parse(element.dataset.isArtist || false),
          },
        }),
    })
  }
}

componentInitializer(initializeListingsPage)

$(document).on("turbo:load", () => {
  const match = document.location.pathname.match(/\/artworks|\/Artwork\//)

  if (match) {
    const setTitle = () => {
      $("#session-body .modal-title").html("Login to follow artists")
    }
    setTitle()
    $(document).on("modal:login-show", setTitle)
  }
})

$(document).on("turbo:load", () => {
  if (window.storedLastScrollLocation != null) {
    $("#back-to-search").show()
  }
})

// Store last location to go back to in /artworks
$(document).on("click", ".Listing_Item a", function () {
  window.storedLastScrollLocation = $(window).scrollTop()
  window.savedSearchUrl = window.location.pathname + window.location.search
})

$(document).on("turbo:load", function () {
  if (window.savedSearchUrl) $("#back-to-search a").attr("href", window.savedSearchUrl)
})

function scrollToLastStoredLocation() {
  const match = window.location.pathname.match(/\/artworks/)

  if (match && window.storedLastScrollLocation != null) {
    const imgLoad = imagesLoaded(document.querySelectorAll(".Listing_Item"))
    imgLoad.on("always", function () {
      $(window).scrollTop(window.storedLastScrollLocation)
      window.storedLastScrollLocation = null
    })
  }
}

// If stored last scroll location present, scroll to preserve last viewed works
window.onpopstate = () => {
  scrollToLastStoredLocation()
}
