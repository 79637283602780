import Vue, { defineAsyncComponent } from "vue"

let Verification = {
  initialize() {
    let el = document.getElementById("vue-podium-verification-page")

    if (el != null) {
      const VerificationPage = defineAsyncComponent(() => import("@/pages/VerificationPage/index.vue"))
      this.app = new Vue({
        el: el,
        render: (h) =>
          h(VerificationPage, {
            props: {
              dashboardApiUrl: el.dataset.dashboardApiUrl,
              emailApiUrl: el.dataset.emailApiUrl,
              phoneApiUrl: el.dataset.phoneApiUrl,
              idApiUrl: el.dataset.idApiUrl,
              showModal: parseInt(el.dataset.showModal),
              defaultEmail: el.dataset.userEmail,
              defaultPhone: el.dataset.vendorPhone,
              defaultEmailVerified: el.dataset.emailVerified == "true",
              defaultPhoneVerified: el.dataset.phoneVerified == "true",
              defaultIdType: el.dataset.governmentIdType,
              defaultIdNumber: el.dataset.governmentIdNumber,
              defaultIdUrl: el.dataset.governmentIdUrl,
            },
          }),
      })
    }
  },
}

document.addEventListener("turbo:load", () => {
  Verification.initialize()
})
