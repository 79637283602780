import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"
import { i18n } from "@/lib/vue/plugins/i18n"

document.addEventListener("turbo:load", async function () {
  const match = location.pathname.match(/\/cart/)

  if (match) {
    const Checkout = defineAsyncComponent(() => import("@/pages/Cart/CheckoutPage.vue"))
    const el = document.getElementById("vue-checkout-page")

    window.checkoutPage = new Vue({
      el,
      i18n,
      store: store,
      apolloProvider,
      render: (h) =>
        h(Checkout),
    })
  }
})
