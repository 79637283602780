const MyArtworkTabs = Object.freeze({
  AVAILABLE: 0,
  GALLERY: 1,
  SOLD: 2,
  UNAVAILABLE: 3,
  DRAFT: 4,
  CHANGE_ORDER: 5,
  SCHEDULED: 6,
  EXCLUSIVE: 7,
  PRINT_ON_DEMAND: 8,
  COMMISSIONED: 9,
})

export default MyArtworkTabs
