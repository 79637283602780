// Directives, Mixin and Filters lives here
import { format } from "date-fns-tz"
import Vue from "vue"
import { i18n } from "@/lib/vue/plugins/i18n"

Vue.directive("visit", {
  bind: function (el, binding) {
    el.addEventListener("click", () => {
      const url = binding.value
      Turbo.visit(url)
    })
  },
})

Vue.filter("formatCents", function (value) {
  const transformed = (value / 100)
    .toLocaleString({
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(".00", "")
  return `${i18n.tc("market.currency.sign")}${transformed}`
})

Vue.filter("formatDollars", (value) => `${i18n.tc("market.currency.sign")}${value}`)

Vue.filter("truncate", function (text, length, clamp) {
  var clamp = clamp || "..."
  var node = document.createElement("div")
  node.innerHTML = text
  var content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
})

Vue.filter("formatDateMonthYear", function (date) {
  return format(new Date(date), "MMMM yyyy")
})

Vue.filter("discountLabel", (discount) => `${discount}% Off`)
