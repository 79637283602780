import Vue from "vue"
import { getFrameName } from "@/lib/Framing.js"

const MODAL_MAP = {
  free: "FreeShippingModal",
  world: "WorldwideShippingModal",
  frame: "FramingModal",
}

const store = Vue.observable({
  cartItem: null,
  listing: {},
  modal: null,
  selectedOption: "",
})

const resetStore = () => {
  store.cartItem = null
  store.selectedOption = ""
}

const getStretching = (option) => {
  if (option == "") return false

  return option.split(".").includes("stretching")
}

const selectedAddonPriceCents = (with_discount = false) => {
  if (store.selectedOption == "") return 0

  if (hasMatte(store.selectedOption)) {
    return edgingPriceCents("matte", with_discount)
  } else if (getFrameName(store.selectedOption)) {
    return edgingPriceCents("frame", with_discount)
  } else {
    return edgingPriceCents("stretch", with_discount)
  }
}

const edgingPriceCents = (frame_option, with_discount = false) => {
  return Reflect.get(store.listing, `${frame_option}_price${with_discount ? "_with_discount" : ""}`).cents
}

const hasMatte = (option) => option.includes("matte")

const setMatte = (matte) => {
  const removed = store.selectedOption.replace(/.matte/, "")
  const appended = store.selectedOption + ".matte"

  store.selectedOption = matte ? appended : removed
}

const setNewOption = (value) => {
  const appendMatte = getters.matteEnabled() && value != "" && !hasMatte(value)
  store.selectedOption = appendMatte ? value + ".matte" : value
}

export const getters = {
  listing: () => store.listing,
  selectedOption: {
    get: () => store.selectedOption,
    set: (value) => setNewOption(value),
  },
  modal: {
    get: () => store.modal,
    set: (name) => (store.modal = MODAL_MAP[name]),
  },
  cartItem: {
    get: () => store.cartItem,
    set: (cartItem) => (store.cartItem = cartItem),
  },
  matteEnabled: () => store.listing.paper,
  paper: () => store.listing.paper,
  matte: {
    get: () => hasMatte(store.selectedOption),
    set: (matte) => setMatte(matte),
  },
  addonSelected: () => hasMatte(store.selectedOption) || store.selectedOption != "",
  stretching: () => getStretching(store.selectedOption),
  selectedFrame: () => getFrameName(store.selectedOption),
  totalCents: () => store.listing.price.cents + selectedAddonPriceCents(),
  totalCentsWithDiscount: () => {
    if (getters.priceHasDiscount()) return store.listing.price_with_discount.cents + selectedAddonPriceCents(true)

    return getters.totalCents()
  },
  priceHasDiscount: () => !!store.listing.price_with_discount,
  availabilityMode() {
    if (!store.listing.on_demand_print && (store.listing.dispatch_from_date || store.listing.artist.holiday_mode)) {
      return "modal"
    }
  },
}

export const mutations = {
  setListing: (listing) => {
    resetStore()
    store.listing = listing
  },
  closeModal: () => (store.modal = null),
  openModal: (name) => (store.modal = MODAL_MAP[name]),
}
