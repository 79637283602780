import Vue, { defineAsyncComponent } from "vue"

const renderPodiumMissingOrderForm = () => {
  const PodiumMissingOrderForm = () => {
    const el = document.getElementById("vue-podium-missing-order-form")

    if (el) {
      const Page = defineAsyncComponent(() => import("@/pages/Podium/MissingOrderForm/index.vue"))

      new Vue({
        el,
        render: (h) =>
          h(Page, {
            props: {
              vendor: el.dataset.vendor,
            },
          }),
      })
    }
  }

  PodiumMissingOrderForm()
}

document.addEventListener("turbo:load", renderPodiumMissingOrderForm)
