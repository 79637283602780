import Vue, { defineAsyncComponent } from "vue"

const renderSpecialisations = () => {
  const loadSpecialisations = () => {
    const el = document.getElementById("vue-specialisations")

    if (el) {
      const Page = defineAsyncComponent(() => import("@/pages/Specialisations/index.vue"))
      new Vue({
        el,
        render: (h) =>
          h(Page, {
            props: {
              apiUrl: el.dataset.apiUrl,
              props: el.dataset.props,
            },
          }),
      })
    }
  }

  loadSpecialisations()
}

document.addEventListener("turbo:load", renderSpecialisations)
