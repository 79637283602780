import Vue, { defineAsyncComponent } from "vue"
import ClipBoard from "@/lib/ClipBoard"

let PromoTools = {
  async initialize() {
    const Chart = await import("chart.js/dist/Chart.min.js")
    let $parent = $(".promo-tools")
    if ($parent != undefined) {
      this.handleClipboard()
      this.renderChart()
      this.loadVue()
    }
  },
  handleClipboard() {
    $(".promo-tools__clipboard").click((e) => {
      ClipBoard.copy(
        document.querySelector(".promo-tools__clipboard"),
        $(e.target).parents(".promo-tools__clipboard").data("url"),
      )
      return false
    })
  },
  renderChart() {
    let $el = $(".promo-tools__chart")
    if ($el.length > 0) {
      new Chart($el, {
        type: "bar",
        data: {
          labels: $el.data("months"),
          datasets: [
            {
              data: $el.data("clicks"),
              fill: false,
              backgroundColor: "rgb(8,159,214)",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  suggestedMin: 0,
                  precision: 0,
                },
              },
            ],
          },
        },
      })
    }
  },
  loadVue() {
    let el = document.getElementById("vue-web-integrations-page")

    if (el != null) {
      const WebIntegrationsPage = defineAsyncComponent(() => import("@/pages/WebIntegrationsPage/index.vue"))
      this.app = new Vue({
        el: el,
        render: (h) =>
          h(WebIntegrationsPage, {
            props: {
              rootUrl: el.getAttribute("data-root-url"),
              apiEndpoint: el.getAttribute("data-api-endpoint"),
              artistId: el.getAttribute("data-artist-id"),
              artistName: el.getAttribute("data-artist-name"),
              listings: JSON.parse(el.getAttribute("data-listings")),
            },
          }),
      })
    }
  },
}

document.addEventListener("turbo:load", () => {
  PromoTools.initialize()
})
