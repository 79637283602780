import Vue, { defineAsyncComponent } from "vue"
import store from "@/store/my_artwork"

document.addEventListener("turbo:load", () => {
  const MyArtworks = document.querySelector(".MyArtworkPage")
  const holidayModeContainer = document.getElementById("vue-my-artwork-holiday-mode")
  const artistData = MyArtworks && JSON.parse(MyArtworks.dataset.artist)

  if (holidayModeContainer) {
    const HolidayMode = defineAsyncComponent(() => import("@/pages/MyArtworkPage/HolidayMode.vue"))
    new Vue({
      el: holidayModeContainer,
      store,
      render: (h) =>
        h(HolidayMode, {
          props: {
            holiday_mode: holidayModeContainer.dataset.holidayMode === "true",
          },
        }),
    })
  }

  const myArtworkPageContainer = document.getElementById("vue-my-artwork-page")

  if (myArtworkPageContainer) {
    const MyArtworkPage = defineAsyncComponent(() => import("@/pages/MyArtworkPage/index.vue"))
    window.listingPage = new Vue({
      el: myArtworkPageContainer,
      store,
      render: (h) =>
        h(MyArtworkPage, {
          props: {
            artistData,
          },
        }),
    })
  }

  const badgesContainer = document.getElementById("vue-my-artwork-badges")

  if (badgesContainer) {
    const Badges = defineAsyncComponent(() => import("@/pages/MyArtworkPage/Badges.vue"))
    new Vue({
      el: badgesContainer,
      store,
      render: (h) =>
        h(Badges, {
          props: {
            artist: artistData.artist,
          },
        }),
    })
  }
})

$(document).on("click", "[role='agree-competition-terms']", function (event) {
  event.preventDefault()
  $("#terms").addClass("hidden")
  $("#enter").removeClass("hidden")
})

$(document).on("change", "[role='category-select']", function (event) {
  if (this.selectedIndex) {
    var buttonState = false
  } else {
    var buttonState = true
  }

  $("[role='submit-btn']").prop("disabled", buttonState)
})
