import Vue, { defineAsyncComponent } from "vue"

document.addEventListener("turbo:load", function () {
  const target = document.getElementById("font-awesome-preview")

  if (target) {
    const FontAwesomePreview = defineAsyncComponent(() => import("@/components/FontAwesomePreview/index.vue"))
    new Vue({
      el: target,
      render: (h) => h(FontAwesomePreview),
    })
  }
})
