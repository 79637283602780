import checkIsMobile from "is-mobile"

function initTopDropdown() {
  const isMobile = checkIsMobile({ tablet: true, featureDetect: true })

  // Go to page instead of dropdown
  $('.btnav__top-dropdown [data-toggle="dropdown"]').on("click", function (e) {
    if (!isMobile) {
      e.preventDefault()

      const path = $(this).prop("href")
      Turbo.visit(path)
    }
  })
}

$(document).on("turbo:load", initTopDropdown)

$(document).on("mouseleave", ".cart-view", function () {
  $(this).parent(".cart-dropdown").removeClass("open")
})

$(document).on("scroll", function () {
  $(".cart-dropdown").removeClass("open")
})

// Mobile side scroller & Popular Styles dropdown

function initPopularStylesDropdown() {
  const popularStylesLink = document.querySelector(".mobile-side-scroller__item.popular-styles")
  const dropdown = document.querySelector(".popular-styles-dropdown__mobile")
  const chevron = document.querySelector(".btnav__chevron-icon")
  const body = document.querySelector("body")
  const html = document.querySelector("html")
  const header = document.getElementById("main-navbar")

  if (popularStylesLink) {
    popularStylesLink.addEventListener("click", (event) => {
      event.preventDefault()

      // Toggle the visibility and update styles for the popular styles dropdown menu
      const isDropdownHidden = dropdown.classList.toggle("hidden")
      const headerDim = header.getBoundingClientRect()
      const announcementBar = document.getElementById("announcements")
      let fixedNavOffset = headerDim.height

      if (announcementBar) {
        fixedNavOffset = headerDim.height + announcementBar.getBoundingClientRect().height
      }
      popularStylesLink.classList.toggle("active", !isDropdownHidden)
      chevron.classList.toggle("active", !isDropdownHidden)
      body.classList.toggle("not-scrollable")
      html.classList.toggle("not-scrollable")
      dropdown.style.height = `calc(90vh - ${fixedNavOffset}px)`
      toggleOverlay()

      // Make other side-scroller elements inactive when "popular styles" is clicked
      const activeElements = document.querySelectorAll(".mobile-side-scroller__item.active:not(.popular-styles)")
      if (activeElements.length) {
        activeElements.forEach((item) => {
          item.classList.remove("active")
        })
      }
    })

    // Add a click-away event listener to close the dropdown when clicking outside
    document.addEventListener("click", (event) => {
      const isClickInsideDropdown = popularStylesLink.contains(event.target) || dropdown.contains(event.target)

      if (!isClickInsideDropdown) {
        removeOverlay()
        dropdown.classList.add("hidden")
        popularStylesLink.classList.remove("active")
        chevron.classList.remove("active")
        body.classList.remove("not-scrollable")
        html.classList.remove("not-scrollable")
      }
    })
  }
}
function toggleOverlay() {
  const overlayId = "popular-styles-dropdown__overlay"
  const overlay = document.getElementById(overlayId)

  if (!overlay) {
    const el = document.createElement("div")
    el.className = "popular-styles-dropdown__overlay"
    el.setAttribute("id", overlayId)
    document.body.appendChild(el)
  } else {
    removeOverlay()
  }
}

function removeOverlay() {
  const overlayId = "popular-styles-dropdown__overlay"
  const overlay = document.getElementById(overlayId)

  if (overlay) {
    document.body.removeChild(overlay)
  }
}

function initBurgerSideNav() {
  $(".hamburger").removeClass("is-active")
  var $body = $("body"),
    $sidenav = $body.find("> .sidenav"),
    $sidenavOverlay = $body.find("> .sidenav-overlay"),
    touchstartX = 0,
    touchendX = 0,
    threshold = 143
  $body.append($(".modal, .pswp"))

  function onTouchStart(event) {
    touchstartX = event.originalEvent.changedTouches[0].screenX
  }

  function onTouchEnd(event) {
    touchendX = event.originalEvent.changedTouches[0].screenX
    if (touchstartX - touchendX > threshold) closeSidenav()
  }

  function openSidenav() {
    $body.addClass("show-sidenav")
    $(".hamburger").addClass("is-active")
    $body.on("touchstart", onTouchStart)
    $body.on("touchend", onTouchEnd)
  }

  function closeSidenav() {
    $body.removeClass("show-sidenav")
    $(".hamburger").removeClass("is-active")
    $body.off("touchstart", onTouchStart)
    $body.off("touchend", onTouchEnd)
  }

  function toggleSidenav() {
    if ($body.hasClass("show-sidenav")) closeSidenav()
    else openSidenav()
  }

  $('[data-toggle="sidenav"]').on("click", function () {
    toggleSidenav()
  })

  $sidenavOverlay.click(function (e) {
    closeSidenav()
    return false
  })

  $sidenav.find(".has-children > a").click(function (e) {
    $(this).parent().toggleClass("active")
    $(this).find("i").toggleClass("fa-chevron-down").toggleClass("fa-chevron-up")
    e.preventDefault()
  })
  $(".see-all").on("click", function (e) {
    e.preventDefault
    $(this).attr("hidden", true)
    $(e.currentTarget.parentNode)
      .find("li")
      .each(function () {
        $(this).removeAttr("hidden")
      })
  })

  const navDropdowns = $(".controlled-dropdown-button")
  const getControlledDropdown = (value) => value.querySelector(".controlled-dropdown")
  const cleanListeners = (value, listener, listenerFunc) => {
    const cleanIntervalListener = () => {
      value.removeEventListener(listener, listenerFunc)
      document.removeEventListener("turbo:before-cache", cleanIntervalListener)
    }
    document.addEventListener("turbo:before-cache", cleanIntervalListener)
  }
  const bindDropdownDelay = (event, value, timeout) => {
    const display = event === "enter" ? "block" : "none"
    const listener = `mouse${event}`
    const listenerFunc = () => {
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => (getControlledDropdown(value).style.display = display), 500)
    }
    value.addEventListener(listener, listenerFunc)
    cleanListeners(value, listener, listenerFunc)
  }
  const bindDropdownMobileBehaviour = (value) => {
    const listener = `click`
    const listenerFunc = () => {
      getControlledDropdown(value).style.display =
        getControlledDropdown(value).style.display === "block" ? "none" : "block"
    }
    value.addEventListener(listener, listenerFunc)
    cleanListeners(value, listener, listenerFunc)
  }
  navDropdowns.each((_, value) => {
    bindDropdownMobileBehaviour(value)
    let timeout = { current: null }
    bindDropdownDelay("enter", value, timeout)
    bindDropdownDelay("leave", value, timeout)
  })
  function cleanBeforeCache() {
    closeSidenav()
  }
  document.removeEventListener("turbo:before-cache", cleanBeforeCache)
  document.addEventListener("turbo:before-cache", cleanBeforeCache)
}

function highlightActiveNavItem() {
  const activeListItem = document.querySelector(".mobile-side-scroller__item.active")
  const fade = document.querySelector(".mobile-side-scroller__fade-right")
  const sideScroller = document.querySelector(".mobile-side-scroller")

  if (activeListItem) {
    const dim = activeListItem.getBoundingClientRect()
    const fadeDim = fade.getBoundingClientRect()
    const location = dim.x + dim.width + fadeDim.width
    const isHiddenBehindVisibleArea = location >= window.innerWidth

    if (isHiddenBehindVisibleArea) {
      sideScroller.scrollLeft += location
    }
  }
}

highlightActiveNavItem()
initBurgerSideNav()
initPopularStylesDropdown()

document.addEventListener("turbo:render", () => {
  initBurgerSideNav()
  highlightActiveNavItem()
  initPopularStylesDropdown()
})
