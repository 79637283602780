import Vue, { defineAsyncComponent } from "vue"

document.addEventListener("turbo:load", () => {
  let subscriptionEl = document.getElementById("vue-billing-subscription")

  if (subscriptionEl != null) {
    const BillingSubscription = defineAsyncComponent(() => import("@/pages/BillingPage/Subscription.vue"))

    new Vue({
      el: subscriptionEl,
      render: (h) =>
        h(BillingSubscription, {
          props: {
            activeCompetitionParticipant: JSON.parse(subscriptionEl.getAttribute("data-active-competition-participant")),
            profileEnhanced: JSON.parse(subscriptionEl.getAttribute("data-profile-enhanced")),
            profileRole: subscriptionEl.getAttribute("data-profile-role"),
            subscriptionTried: JSON.parse(subscriptionEl.getAttribute("data-subscription-tried")),
            monthlyPrice: parseInt(subscriptionEl.getAttribute("data-monthly-price")),
            yearlyPrice: parseInt(subscriptionEl.getAttribute("data-yearly-price")),
            apiUrl: subscriptionEl.getAttribute("data-api-url"),
          },
        }),
    })
  }
})
