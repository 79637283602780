const ClipBoard = (function (window, document, navigator) {
  var textArea, copy

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i)
  }

  function createTextArea(text) {
    textArea = document.createElement("textArea")
    textArea.value = text
    document.body.appendChild(textArea)
  }

  function selectText() {
    var range, selection

    if (isOS()) {
      range = document.createRange()
      range.selectNodeContents(textArea)
      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      textArea.setSelectionRange(0, 999999)
    } else {
      textArea.select()
    }
  }

  function copyToClipboard() {
    document.execCommand("copy")
    document.body.removeChild(textArea)
  }

  copy = function (element, text) {
    try {
      createTextArea(text)
      selectText()
      copyToClipboard()
      if (isOS()) {
        scrollToOriginalElement(element)
      }
    } catch (err) {
      return false
    }
    return true
  }

  return {
    copy: copy,
  }
})(window, document, navigator)

export default ClipBoard
