import Vue from "vue"
import Vuex from "vuex"

import FilterModule from "@/store/my_artwork/filters"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    filter: FilterModule,
  },
})
