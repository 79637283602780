import Vue, { defineAsyncComponent } from "vue"
import { apolloProvider } from "@/lib/Apollo"
export const podiumProfilePage = () => location.pathname.match(/podium\/profile\/?(\w.+)?/)

const renderPodiumProfilePage = () => {
  const loadPodiumProfilePage = () => {
    const el = document.getElementById("vue-podium-profile-page")
    const Page = defineAsyncComponent(() => import("@/pages/PodiumProfileCreate/index.vue"))
    new Vue({
      el,
      apolloProvider,
      render: (h) =>
        h(Page, {
          props: {
            profile: el.dataset.profile,
            apiUrl: el.dataset.apiurl,
            updateUserUrl: el.dataset.updateuserurl,
          },
        }),
    })
  }

  podiumProfilePage() && loadPodiumProfilePage()
}

document.addEventListener("turbo:load", renderPodiumProfilePage)
