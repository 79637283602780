import ClipBoard from "@/lib/ClipBoard"

// This is a legacy share class to support all the places where we still don't
// have a Vue page and cannot use it to handle the clipboard directly. Once all
// reference pages are converted to Vue, we can safely delete this file.
let Share = {
  handleCopyToClipboard() {
    var element = $(".copy-listing")
    if (element.length > 0) {
      element.on("click", function () {
        var formInput = $(this).closest(".input-group").find(".listing-copy-url")
        var successful = ClipBoard.copy(document.querySelector(".listing-copy-url"), formInput.val())
        if (successful) {
          let action_label = formInput.hasClass("confirmation") ? "Copylink_confirmation" : "Copylink_upload"
          // register ga event
          ga("send", "event", action_label, "click", formInput.val())
        }
      })
    }
  },
}

document.addEventListener("turbo:load", () => {
  Share.handleCopyToClipboard()
})
