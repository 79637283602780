import Vue, { defineAsyncComponent } from "vue"
import Http from "@/lib/Http"

const DashboardCharts = {
  async initialize({
    id,
    enhancedProfile,
    featuredBadge,
    listingsApiUrl,
    artworkStatisticsTooltipUrl,
    newMyBillingUrl,
  }) {
    const el = document.getElementById("vue-dashboard-charts")

    if (el) {
      const DashboardPage = defineAsyncComponent(() => import("@/pages/DashboardPage/index.vue"))
      const Chart = await import("chart.js/dist/Chart.min.js")

      Chart.defaults.global.legend.display = false
      Chart.defaults.global.responsive = true
      Chart.defaults.global.tooltips.mode = "index"
      Chart.defaults.global.tooltips.intersect = true
      Chart.defaults.global.defaultFontSize = 10
      Chart.defaults.global.animation.duration = 3000

      this.app = new Vue({
        el,
        render: (h) =>
          h(DashboardPage, {
            props: {
              id,
              listingsApiUrl,
              featuredBadge,
              enhancedProfile,
              artworkStatisticsTooltipUrl,
              newMyBillingUrl,
            },
          }),
      })
    }
  },
}

const ShipperInfo = {
  async initialize({ shipperInfo }) {
    const el = document.getElementById("vue-shipper-info")
    if (el) {
      const view = defineAsyncComponent(() => import("@/pages/DashboardPage/ShipperInfo.vue"))

      this.app = new Vue({
        el,
        render: (h) =>
          h(view, {
            props: {
              shipperInfo,
              tooltipDirection: "left",
            },
          }),
      })
    }
  },
}

const Tracker = {
  async initialize({ tracker, enhancedProfile }) {
    const el = document.getElementById("vue-dashboard-tracker")
    if (el) {
      const view = defineAsyncComponent(() => import("@/pages/DashboardPage/Tracker.vue"))
      this.app = new Vue({
        el,
        render: (h) =>
          h(view, {
            props: {
              tracker,
              enhancedProfile,
            },
          }),
      })
    }
  },
}

const Sales = {
  async initialize({ sales }) {
    const el = document.getElementById("vue-dashboard-sales")
    if (el) {
      const view = defineAsyncComponent(() => import("@/pages/DashboardPage/Sales.vue"))
      this.app = new Vue({
        el,
        render: (h) =>
          h(view, {
            props: {
              sales,
            },
          }),
      })
    }
  },
}

const Meta = {
  async initialize({ meta }) {
    const el = document.getElementById("vue-dashboard-meta")
    if (el) {
      const view = defineAsyncComponent(() => import("@/pages/DashboardPage/Meta.vue"))
      this.app = new Vue({
        el,
        render: (h) =>
          h(view, {
            props: {
              meta,
            },
          }),
      })
    }
  },
}

document.addEventListener("turbo:load", async () => {
  const el = document.getElementById("dashboard-page")
  if (el) {
    Http.get(el.dataset.apiUrl).then((response) => {
      const {
        shipper_info,
        id,
        tracker,
        sales,
        meta,
        featured_badge,
        api,
        artwork_statistics_tooltip_url,
        new_my_billing_url,
      } = response.data.artist

      DashboardCharts.initialize({
        id,
        listingsApiUrl: api.listings,
        enhancedProfile: el.dataset.enhancedProfile === "true",
        featuredBadge: featured_badge,
        artworkStatisticsTooltipUrl: artwork_statistics_tooltip_url,
        newMyBillingUrl: new_my_billing_url,
      })
      ShipperInfo.initialize({ shipperInfo: shipper_info })
      Tracker.initialize({ tracker, enhancedProfile: el.dataset.enhancedProfile === "true" })
      Sales.initialize({ sales })
      Meta.initialize({ meta })
    })
  }
})
