import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"

document.addEventListener("turbo:load", function () {
  const target = document.getElementById("nav-favourite")

  if (target) {
    const Favourite = defineAsyncComponent(() => import("@/components/Nav/Favourite.vue"))

    new Vue({
      el: target,
      store,
      apolloProvider,
      render: (h) =>
        h(Favourite, {
          props: {
            onboardPath: target.dataset.onboardPath,
            onboarded: target.dataset.onboarded === "true",
          },
          on: {
            "onboarded:close": function () {
              this.onboarded = false
            },
          },
        }),
    })
  }
})
