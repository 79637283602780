import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"
import { i18n } from "@/lib/vue/plugins/i18n"

function createVueComponent(target, asyncComponent) {
  const el = document.getElementById(target)

  if (el) {
    new Vue({
      el,
      store,
      apolloProvider,
      i18n,
      render: (h) => h(asyncComponent),
    })
  }
}

document.addEventListener("turbo:load", function () {
  createVueComponent("cart-counter-btn", () => import("@/components/Cart/CounterBtn.vue"))

  const mainModalTarget = document.createElement("div")
  document.body.appendChild(mainModalTarget)

  if (mainModalTarget) {
    const AvailabilityModal = defineAsyncComponent(() => import("@/components/Cart/AvailabilityModal.vue"))
    new Vue({
      el: mainModalTarget,
      store,
      apolloProvider,
      render: (h) =>
        h(AvailabilityModal),
    })
  }
})
