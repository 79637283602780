import difference from "lodash/difference"

export const FRAME_NAMES = {
  oak: "natural",
}

const NON_FRAMES = ["stretching", "matte"]

export function onlyStretching(option) {
  const options = option.split(".")

  return options.includes("stretching") && options.length === 1
}

export function getFrameName(option) {
  if (option == "") return null

  const frame = difference(option.split("."), NON_FRAMES)[0]

  return FRAME_NAMES[frame] || frame
}
