const initMapbox = () => {
  const mapElement = document.getElementById("galleries-map")

  if (mapElement) {
    mapboxgl.accessToken = window.mapboxApiKey

    const map = new mapboxgl.Map({
      container: "galleries-map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [133.319663, -25.23833],
      zoom: 3.4,
    })

    const mapPage = mapElement.getAttribute("data-map-page-url")
    fetch(mapPage)
      .then((response) => response.json())
      .then((markers) => {
        markers.forEach((marker) => {
          const popupInfo =
            "<div class='popup-container max-w-lg flex text-center bg-white p-2'>" +
            "<img class='img-responsive h-16 w-16' src=" +
            marker.photo_uri +
            ">" +
            "<a href=" +
            marker.path +
            " class='self-center ml-3 font-bold text-sm text-black focus:no-underline hover:text-blue'>" +
            marker.name +
            "</a></div>"
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: true,
            closeOnMove: true,
          }).setHTML(popupInfo)

          new mapboxgl.Marker().setLngLat([marker.lng, marker.lat]).setPopup(popup).addTo(map)
        })
        map.addControl(new mapboxgl.NavigationControl())
      })
  }
}

document.addEventListener("turbo:load", initMapbox)
