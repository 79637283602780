// Magic form
$(document).on("focus", ".form-magic input", function (e) {
  $(this).siblings(".control-label").addClass("is-active")
})

$(document).on("focusout", ".form-magic input", function (e) {
  $(this).siblings(".control-label").removeClass("is-active")
})

$(document).on("turbo:load", function () {
  var selector = ".blue-selector > input, .blue-selector > textarea, .blue-selector > select"
  $(document).on("focus", selector, function () {
    $(this).siblings("label").addClass("text-primary")
  })
  $(document).on("focusout", selector, function () {
    $(this).siblings("label").removeClass("text-primary")
  })
})
