import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"

document.addEventListener("turbo:load", () => {
  let element = document.getElementById("vue-artist-profile-page")

  if (element != null) {
    const ArtistProfilePage = defineAsyncComponent(() => import("@/pages/ArtistProfilePage/index.vue"))

    window.listingPage = new Vue({
      el: element,
      apolloProvider,
      store,
      render: (h) =>
        h(ArtistProfilePage, {
          props: {
            artistData: JSON.parse(element.attributes["data-artist"].value),
            sortOptions: JSON.parse(element.attributes["data-sort-options"].value),
            showPortfolio: element.attributes["data-show-portfolio"].value === "true",
          },
        }),
    })
    // Workaround to avoid the page to scroll all the way down
    $(window).scrollTop(0)
  }
})
