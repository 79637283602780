import Vue from "vue"
import { ValidationProvider, ValidationObserver, extend } from "vee-validate"
import * as rules from "vee-validate/dist/rules"

Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

extend("abnNumberLength", {
  validate(value) {
    const [min, max] = [9, 11]
    return value.length >= min && value.length <= max
  },
  message: "Must contain digits, minimum 9, maximum 11",
})

extend("url", {
  validate(value) {
    return value.startsWith("https://") || value.startsWith("http://")
  },
  message: "Please enter a complete URL including https:// or http://",
})

extend("required", {
  ...rules.required,
  message: "This field is required",
})

extend("numeric", {
  ...rules.numeric,
  message: "Must contain only digits",
})
