import Vue, { defineAsyncComponent } from "vue"
import { apolloProvider } from "@/lib/Apollo"
import store from "@/store"
import { mutations } from "@/pages/Listing/store.js"
import reject from "lodash/reject"
import { i18n } from "@/lib/vue/plugins/i18n"

export const artworkPage = () => location.pathname.match(/\/Artwork\/(\w.+)?/)

const loadListingData = () => {
  artworkPage() && mutations.setListing(window.listingData)
}

document.addEventListener("turbo:load", loadListingData)

const renderListingDetails = () => {
  const renderComponent = (elID, props = {}, asyncComponent) => {
    const el = document.getElementById(elID)

    if (!el) return

    new Vue({
      el,
      apolloProvider,
      store,
      render: (h) =>
        h(asyncComponent, {
          props,
        }),
    })
  }

  if (artworkPage()) {
    const ListingDetails = defineAsyncComponent(() => import("@/pages/Listing/Details/index.vue"))
    const listingDetailsData = document.getElementById("listing-details-data")
    const { afterpayEligible, paylaterEligible } = listingDetailsData?.dataset || {}
    const toBool = (val) => val === "true"
    const props = {
      afterpayEligible: toBool(afterpayEligible),
      paylaterEligible: toBool(paylaterEligible),
    }
    renderComponent("listing-details", { ...props, isDesktop: false }, ListingDetails)
    renderComponent("listing-details-top", { ...props, isDesktop: true }, ListingDetails)
  }
}

document.addEventListener("turbo:load", renderListingDetails)

const renderListingRecentViews = () => {
  const localStorageCheck = () => {
    const test = "__test__"
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      return true
    } catch (e) {
      return false
    }
  }

  const storeListingData = () => {
    if (localStorageCheck()) {
      let recentViews = []

      if (localStorage.recentViewsId) {
        recentViews = reject(JSON.parse(localStorage.recentViewsId), (listingId) => {
          return listingId == window.listingData.id
        }).slice(0, 24)
      }

      recentViews.push(window.listingData.id)
      localStorage.recentViewsId = JSON.stringify(recentViews)

      return recentViews
    } else {
      return []
    }
  }

  const loadListingRecentViews = () => {
    const el = document.getElementById("listing-recentviews")
    const ListingRecentViews = defineAsyncComponent(() => import("@/pages/Listing/RecentViews.vue"))

    new Vue({
      el,
      store,
      apolloProvider,
      render: (h) =>
        h(ListingRecentViews, {
          props: {
            apiUrl: el.dataset.apiUrl,
            listingId: window.listingData.id,
            listingIds: storeListingData(),
          },
        }),
    })
  }

  artworkPage() && loadListingRecentViews()
}

document.addEventListener("turbo:load", renderListingRecentViews)

const renderListingRecommendations = () => {
  const loadListingRecommendations = () => {
    const el = document.getElementById("listing-recommendations")
    const ListingRecommendations = defineAsyncComponent(() => import("@/pages/Listing/Recommendations.vue"))

    new Vue({
      el,
      store,
      apolloProvider,
      render: (h) =>
        h(ListingRecommendations, {
          props: {
            apiUrl: el.dataset.apiUrl,
          },
        }),
    })
  }

  artworkPage() && loadListingRecommendations()
}

document.addEventListener("turbo:load", renderListingRecommendations)

const renderMoreFeaturedArt = () => {
  const loadMoreFeaturedArt = () => {
    const el = document.getElementById("listing-morefeaturedartfrom")
    const MoreFeaturedArt = defineAsyncComponent(() => import("@/pages/Listing/MoreFeaturedArt.vue"))

    new Vue({
      el,
      store,
      apolloProvider,
      render: (h) =>
        h(MoreFeaturedArt, {
          props: {
            apiUrl: el.dataset.apiUrl,
            artistName: el.dataset.artistName,
          },
        }),
    })
  }

  artworkPage() && loadMoreFeaturedArt()
}

document.addEventListener("turbo:load", renderMoreFeaturedArt)

const renderListingModal = () => {
  const loadListingModal = () => {
    const el = document.getElementById("listing-modal")
    const ListingModal = defineAsyncComponent(() => import("@/pages/Listing/Modal/index.vue"))

    new Vue({
      el,
      i18n,
      render: (h) => h(ListingModal, {}),
    })
  }

  artworkPage() && loadListingModal()
}

document.addEventListener("turbo:load", renderListingModal)

const renderListingDescription = () => {
  const loadListingDescription = () => {
    const el = document.getElementById("listing-description")
    const ListingDescription = defineAsyncComponent(() => import("@/pages/Listing/Description/index.vue"))

    new Vue({
      el,
      apolloProvider,
      store,
      render: (h) => h(ListingDescription, {}),
    })
  }

  artworkPage() && loadListingDescription()
}

document.addEventListener("turbo:load", renderListingDescription)

const renderListingEstimatedShipping = () => {
  const loadListingEstimatedShipping = () => {
    const el = document.getElementById("listing-estimated-shipping")
    const ListingEstimatedShipping = defineAsyncComponent(() => import("@/pages/Listing/EstimatedShipping/index.vue"))

    new Vue({
      el,
      apolloProvider,
      store,
      render: (h) => h(ListingEstimatedShipping, {}),
    })
  }

  artworkPage() && loadListingEstimatedShipping()
}

document.addEventListener("turbo:load", renderListingEstimatedShipping)

const renderListingEstimatedShippingTop = () => {
  const loadListingEstimatedShippingTop = () => {
    const el = document.getElementById("listing-estimated-shipping-top")
    const ListingEstimatedShipping = defineAsyncComponent(() => import("@/pages/Listing/EstimatedShipping/index.vue"))

    new Vue({
      el,
      apolloProvider,
      store,
      render: (h) => h(ListingEstimatedShipping, {}),
    })
  }

  artworkPage() && loadListingEstimatedShippingTop()
}

document.addEventListener("turbo:load", renderListingEstimatedShippingTop)

// Modal launcher outside Vue
document.addEventListener("turbo:load", () => {
  artworkPage() &&
    (() => {
      const listingModalButtons = document.querySelectorAll("[data-listing-modal]")

      const launchModal = (el) => () => {
        event.preventDefault()
        mutations.openModal(el.dataset.listingModal)
      }

      listingModalButtons.forEach((el) => {
        el.addEventListener("click", launchModal(el))
      })
    })()
})

// Alerts "N people are looking at this artwork now"
const renderUrgencyAlert = () => {
  const loadUrgencyAlert = () => {
    const elements = document.querySelectorAll(".vue-show-urgency") // mobile & desktop versions
    const UrgencyAlert = defineAsyncComponent(() => import("@/components/Listing/UrgencyAlert.vue"))

    elements.forEach((item) => {
      new Vue({
        el: item,
        render: (h) =>
          h(UrgencyAlert, {
            props: {
              count: item.dataset.count,
            },
          }),
      })
    })
  }

  artworkPage() && loadUrgencyAlert()
}

document.addEventListener("turbo:load", renderUrgencyAlert)

// Alert "[x] people have added this to their cart"
const renderCartUrgencyAlert = () => {
  const loadCartUrgencyAlert = () => {
    const elements = document.querySelectorAll(".vue-show-cart-urgency") // mobile & desktop versions
    const CartUrgencyAlert = defineAsyncComponent(() => import("@/components/Listing/CartUrgencyAlert.vue"))

    elements.forEach((item) => {
      new Vue({
        el: item,
        render: (h) =>
          h(CartUrgencyAlert, {
            props: {
              count: item.dataset.count,
            },
          }),
      })
    })
  }

  artworkPage() && loadCartUrgencyAlert()
}

document.addEventListener("turbo:load", renderCartUrgencyAlert)
