import { utcToZonedTime, format } from "date-fns-tz"

// Read how to format here
// https://date-fns.org/v2.11.0/docs/format

document.addEventListener("turbo:load", makeTime)

function makeTime() {
  const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone

  document.querySelectorAll("[data-local-tz]").forEach((el) => {
    if (el.timeTouched) return

    el.timeTouched = true
    const isoTime = el.innerHTML.trim()
    const pattern = el.getAttribute("data-local-tz") || "EE, MMM co y"
    //const pattern = 'MMM c, h:ma'

    if (isoTime != "") {
      const zonedDate = utcToZonedTime(isoTime, userTz)
      const formatted = format(zonedDate, pattern, { timeZone: userTz })

      el.innerHTML = formatted
    }
  })
}
