let firstLoad = true

export default function componentInitializer(func) {
  // Handle the component when user submit back/forward buttons
  document.addEventListener("turbo:load", () => {
    if (firstLoad) {
      firstLoad = false
      return
    }

    func()
  })

  // Increase the speed of initializing the component (2x)
  // Handle the component with this listener when the page load first time
  document.addEventListener("DOMContentLoaded", func)
}
